.cursor-pointer {
    cursor: pointer;
}

.list-style-none {
    list-style: none;
}

.show-on-laptop {
    display: block;
}

.show-on-tablet {
    display: none;
}

.show-on-mobile {
    display: none;
}

.show-on-tablet-laptop {
    display: block;
}

.show-on-tablet-mobile {
    display: none;
}

.show-on-laptop-mobile {
    display: block;
}

.show-item {
    display: block;
}

.hide-item {
    display: none;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-align-center {
    text-align: center;
}

.ordinal {
    vertical-align: baseline;
    font-size: smaller;
}

.PayPal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.paypal-short-logo {
    height: 30px;
    width: 45px;
}

.paypal-txt p {
    color: var(--tertiary-font-color);
}

.textPaylater {
    font-style: oblique;
    font-size: var(--font-size-medium);
    line-height: var(--font-line-medium);
}

.paypal-logo {
    width: 68px;
    height: 41px;
    margin-top: -9px;
    margin-left: -8px;
}

.disable-scroll {
    overflow: hidden;
}

@media (max-width: 500px) {

    /* CSS styles for mobile devices */
    .show-on-tablet {
        display: none;
    }

    .show-on-mobile {
        display: block;
    }

    .show-on-tablet-laptop {
        display: none;
    }

    .show-on-laptop-mobile {
        display: block;
    }
}

@media (max-width: 991px) {

    /* CSS styles for tablet devices */
    .show-on-laptop {
        display: none;
    }

    .show-on-tablet {
        display: block;
    }

    .show-on-tablet-mobile {
        display: block;
    }

    .show-on-laptop-mobile {
        display: none;
    }
}

.skeleton-box {
    display: inline-block;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: #DDDBDD;

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(90deg,
                rgba(#fff, 0) 0,
                rgba(#fff, 0.2) 20%,
                rgba(#fff, 0.5) 60%,
                rgba(#fff, 0));
        animation: shimmer 1s infinite;
        content: '';
    }

    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
}